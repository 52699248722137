// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from '@plesk/ui-library';
import { redirect } from 'jsw';
import { withLoginLayout } from './LoginLayout';

const GetPassword = ({ values }) => {
    const emailRef = useRef();

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    const handleCancelClick = () => {
        redirect('/login_up.php');
    };

    return (
        <form
            id="form-changepassword-getlink"
            encType="application/x-www-form-urlencoded"
            method="post"
        >
            <div id="getLinkSection" className="form-box">
                <div id="getLinkSection-content-area">
                    <div className="login-info">
                        <h3><Translate content="label" /></h3>
                        <Translate content="text" />
                    </div>

                    <div id="getLinkSection-email-form-row" className="form-row">
                        <div className="field-name"><label htmlFor="getLinkSection-email"><Translate content="emailLabel" />&nbsp;</label></div>
                        <div className="field-value">
                            <input
                                ref={emailRef}
                                type="text"
                                name="email"
                                id="getLinkSection-email"
                                defaultValue={values.email}
                                className="input-text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="btns-box">
                <div className="box-area">
                    <div className="form-row">
                        <div className="field-name" />
                        <div className="field-value">
                            <button id="btn-send" className="btn" type="submit" value="" name="send"><Translate content="send" /></button>
                            <button id="btn-cancel" className="btn" type="button" value="" name="cancel" onClick={handleCancelClick}><Translate content="cancel" /></button>

                            <input type="image" src={require('images/blank.gif')} style={{ border: 0, height: 0, width: 0, position: 'absolute' }} />
                        </div>
                    </div>
                </div>
            </div>

            {document.getElementById('forgery_protection_token') ? (
                <input
                    type="hidden"
                    name="forgery_protection_token"
                    value={document.getElementById('forgery_protection_token').content}
                />
            ) : null}
        </form>
    );
};

GetPassword.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string,
    }).isRequired,
};

export default withLoginLayout(GetPassword);
