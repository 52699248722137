// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement, useRef } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import LicenseStatus from './LicenseStatus';
import MainHeaderSearch from './MainHeaderSearch';
import MainHeaderNavbar from './MainHeaderNavbar';

const PageHeader = ({ licenseStatus, searchUrl, ...props }) => {
    const headerRef = useRef();
    const handleResize = ({ bounds }) => {
        headerRef.current.parentNode.style.height = `${bounds.height}px`;
    };

    return (
        <Measure
            onResize={handleResize}
            bounds
            innerRef={node => {
                headerRef.current = node;
            }}
        >
            {({ measureRef }) => (
                <div
                    className="pul-layout__header-inner"
                    data-type="page-header"
                    ref={measureRef}
                >
                    {licenseStatus ? (
                        <div className="pul-layout__header-addon">
                            <LicenseStatus {...licenseStatus} />
                        </div>
                    ) : null}
                    <div className="pul-layout__header-content js-scrollbar-spacer">
                        <div className="pul-layout__header-content-inner">
                            <div className="main-header">
                                {searchUrl ? (
                                    <MainHeaderSearch url={searchUrl} headerRef={headerRef} />
                                ) : null}
                                <MainHeaderNavbar {...props} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Measure>
    );
};

PageHeader.propTypes = {
    licenseStatus: PropTypes.object,
    searchUrl: PropTypes.string,
};

PageHeader.defaultProps = {
    licenseStatus: null,
    searchUrl: null,
};

export default PageHeader;
