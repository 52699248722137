// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { keyCode, redirect, escapeHtml, escapeAttribute } from 'jsw';
import { useConfigContext } from 'plesk/components/ConfigContext';

const MainHeaderSearch = ({ url, headerRef }, { locale }) => {
    const searchActionDelay = 200;
    const containerRef = useRef();
    const searchTermRef = useRef();
    const iconStateRef = useRef();
    const searchResultsBlockRef = useRef();
    const timer = useRef(null);
    const enterPressed = useRef(false);
    const pendingRequest = useRef(null);
    const skipKeyUp = useRef(false);
    const touchMoved = useRef(false);
    const config = useConfigContext();

    const [isSearchFocused, setSearchFocused] = useState(false);
    const [isOpened, setOpened] = useState(false);

    const fatalError = message => {
        // eslint-disable-next-line no-alert
        alert(message);
    };

    const handlePaste = () => scheduleSearch();

    const handleKeyUp = event => {
        if (skipKeyUp.current) {
            return;
        }
        if (event.keyCode === keyCode.ESC) {
            resetSearch({ resetValue: false });
            return;
        }
        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW, keyCode.LEFT_ARROW, keyCode.RIGHT_ARROW, keyCode.ENTER].indexOf(event.keyCode) !== -1) {
            return;
        }

        scheduleSearch();
    };

    const handleKeyDown = event => {
        skipKeyUp.current = event.ctrlKey || event.metaKey;

        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW].indexOf(event.keyCode) !== -1) {
            onArrowKeyPressed(event.keyCode);
            event.preventDefault();
        }

        enterPressed.current = (keyCode.ENTER === event.keyCode);
        if (enterPressed.current) {
            scheduleSearch();
        }
    };

    const handleFocus = () => {
        if (resetTimeout) {
            clearTimeout(resetTimeout);
        }

        resetSearch();
        setSearchFocused(true);

        document.addEventListener('touchstart', onDocumentTouchStart);
        document.addEventListener('touchmove', onDocumentTouchMove);
        document.addEventListener('touchend', onDocumentTouchEnd);
    };

    let resetTimeout = null;
    const handleBlur = () => {
        if (resetTimeout) {
            clearTimeout(resetTimeout);
        }

        resetTimeout = setTimeout(() => {
            resetSearch();
            setSearchFocused(false);
        }, 300);
    };

    const scheduleSearch = () => {
        if (searchTermRef.current.value) {
            iconStateRef.current.classList.add('icon-indicator');
            iconStateRef.current.classList.remove('icon-search');
        }

        abortPreviousSearch();
        timer.current = setTimeout(findTerm, searchActionDelay);
    };

    const abortPreviousSearch = () => {
        if (pendingRequest.current) {
            const abort = pendingRequest.current.abort.bind(pendingRequest.current);
            // global state is cleared first due to onSearchComplete callback
            pendingRequest.current = null;
            abort();
        }

        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    const onSearchSuccess = response => {
        if (searchTermRef.current.value !== response.request.options.parameters.term) {
            return;
        }

        let data;
        try {
            data = JSON.parse(response.responseText);
        } catch (e) {
            fatalError(`Failed to parse JSON response: ${e.message}`);
            return;
        }

        if ('error' === data.status) {
            let result = '';
            data.statusMessages.each(function (message) {
                result += `${message.title}: ${message.content}\n`;
            });
            fatalError(result);
            return;
        }

        if (enterPressed.current) {
            goToSelectedItem(data);
        } else {
            showResults(data);
        }
    };

    const onSearchFailure = response => {
        fatalError(`Search request failed due to following error: ${response.responseText}`);
    };

    const onSearchComplete = response => {
        if (!response || pendingRequest.current === response.request) {
            iconStateRef.current.classList.remove('icon-indicator');
            iconStateRef.current.classList.add('icon-search');
        }
    };

    const goToSelectedItem = results => {
        let activeRow = searchResultsBlockRef.current.querySelector('li.active');

        if (!activeRow) {
            activeRow = searchResultsBlockRef.current.querySelector('li');
        }

        if (activeRow && activeRow.querySelector('a')) {
            redirect(activeRow.querySelector('a').href, null, activeRow.querySelector('a').target);
        } else if (results.records.length) {
            redirect(results.records[0].link, null, results.records[0].target);
        } else {
            showResults(results);
        }
    };

    const showResults = results => {
        let resultsHtml = '';

        if (0 === results.records.length) {
            resultsHtml += `<li><div class="search-results-note">${locale.lmsg('search-bar.nothingFound')}</div></li>`;
        } else {
            results.records.each(({ details = '', target, icon, link, title }) => {
                let linkTarget = '';
                if (target) {
                    linkTarget = `target="${target}"`;
                }
                const iconUrl = (
                    !icon ||
                    icon.startsWith(Jsw.skinUrl) ||
                    icon.startsWith('http://') ||
                    icon.startsWith('https://') ||
                    icon.startsWith('/modules/')
                ) ? icon : `${Jsw.skinUrl}${icon}`;

                resultsHtml += (
                    `<li>
                        <a href="${escapeAttribute(link)}" title="${escapeAttribute(details)}" ${linkTarget}>
                            ${iconUrl ? `<i class="icon-" style="background-image: url(${escapeHtml(iconUrl)})"></i>` : ''}
                            ${escapeHtml(title)}
                        </a>
                    </li>`
                );
            });

            if (results.meta.moreResultsFound) {
                resultsHtml += (
                    `<li>
                        <div class="search-results-note">${locale.lmsg('search-bar.moreResultsFound', { limit: config.search.limit })}</div>
                    </li>`
                );
            }
        }

        searchResultsBlockRef.current.innerHTML = resultsHtml;

        const links = searchResultsBlockRef.current.querySelectorAll('a');
        links.forEach(link => {
            link.addEventListener('mouseover', onItemMouseOver);
            link.addEventListener('mouseout', onItemMouseOut);
        });
        if (0 !== links.length) {
            links[0].closest('li').classList.add('active');
        }

        setOpened(true);
    };

    const onItemMouseOver = ({ target }) => {
        const oldActiveLink = searchResultsBlockRef.current.querySelector('li.active');

        if (oldActiveLink) {
            oldActiveLink.classList.remove('active');
        }

        target.closest('li').classList.add('active');
    };

    const onItemMouseOut = ({ target }) => {
        target.closest('li').classList.remove('active');
    };

    const resetSearch = ({ resetValue = true } = {}) => {
        if (resetValue) {
            searchTermRef.current.value = '';
        }
        setOpened(false);

        abortPreviousSearch();
        onSearchComplete();
    };

    const onArrowKeyPressed = key => {
        if (0 === searchResultsBlockRef.current.querySelectorAll('a').length) {
            return;
        }

        const activeRow = searchResultsBlockRef.current.querySelector('li.active');

        if (!activeRow) {
            searchResultsBlockRef.current.querySelector('li').classList.add('active');
            return;
        }

        if (keyCode.DOWN_ARROW === key) {
            const nextRow = activeRow.nextElementSibling;

            if (nextRow && nextRow.querySelector('a')) {
                activeRow.classList.remove('active');
                nextRow.classList.add('active');
            }
        }

        if (keyCode.UP_ARROW === key) {
            const prevRow = activeRow.previousElementSibling;

            if (prevRow && prevRow.querySelector('a')) {
                activeRow.classList.remove('active');
                prevRow.classList.add('active');
            }
        }
    };

    const findTerm = () => {
        const term = searchTermRef.current.value;

        if ('' === term) {
            resetSearch();
            return;
        }

        pendingRequest.current = new Ajax.Request(
            url,
            {
                method: 'get',
                parameters: { term },
                onSuccess: onSearchSuccess,
                onFailure: onSearchFailure,
                onComplete: onSearchComplete,
            }
        );
    };

    const onDocumentTouchStart = useCallback(() => {
        touchMoved.current = false;
    }, [touchMoved]);
    const onDocumentTouchMove = useCallback(() => {
        touchMoved.current = true;
    }, [touchMoved]);
    const onDocumentTouchEnd = useCallback(() => {
        if (touchMoved.current) {
            return;
        }

        searchTermRef.current.blur();

        document.removeEventListener('touchstart', onDocumentTouchStart);
        document.removeEventListener('touchmove', onDocumentTouchMove);
        document.removeEventListener('touchend', onDocumentTouchEnd);
    }, [touchMoved, searchTermRef, onDocumentTouchStart, onDocumentTouchMove]);

    return (
        <div
            ref={containerRef}
            className="main-header-search"
            onClick={() => {
                searchTermRef.current.focus();
            }}
            onMouseEnter={() => {
                headerRef.current.classList.add('page-header-search-hover');
            }}
            onMouseLeave={() => {
                headerRef.current.classList.remove('page-header-search-hover');
            }}
            onTouchEnd={e => {
                e.stopPropagation();
            }}
        >
            <div className={classNames('form-group', 'dropdown', isSearchFocused && 'search-focused', isOpened && 'open')}>
                <input
                    ref={searchTermRef}
                    id="searchTerm"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder={locale.lmsg('search-bar.fieldStub')}
                    onPaste={handlePaste}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <span className="form-control-feedback icon-search" ref={iconStateRef} />
                <span
                    className="form-control-feedback icon-close"
                    tabIndex="0"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
                <ul
                    ref={searchResultsBlockRef}
                    id="searchResultsBlock"
                    className="dropdown-menu dropdown-icon-menu"
                />
            </div>
        </div>
    );
};


MainHeaderSearch.propTypes = {
    url: PropTypes.string.isRequired,
    headerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

MainHeaderSearch.contextTypes = {
    locale: PropTypes.object,
};

export default MainHeaderSearch;
