// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { ModalDialogBox } from './modal-dialog-box';
import render from './render';
import escapeAttribute from './escapeAttribute';

export class Popup extends ModalDialogBox {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._popupContentAreaId = `${this._id}-popup-content-area`;
        this._titleAreaId = `${this._id}-title-area`;
        this._actionButtonsId = `${this._id}-action-buttons`;
        this._rightActionButtonsAreaId = `${this._id}-right-action-buttons-area`;

        this._contentHeight = this._getConfigParam('contentHeight', null);
        this._closeButtonEnabled = this._getConfigParam('closeButtonEnabled', false);
        this._cls = this._getConfigParam('popupCls', 'popup-panel');
        this._titleCls = this._getConfigParam('titleCls', '');
        this._buttons = this._getConfigParam('buttons', []);
    }

    _initComponentElement() {
        super._initComponentElement();
        let closeButton = '';
        if (this._closeButtonEnabled) {
            closeButton = `<span class="close" id="${this._id}-close"></span>`;
        }
        let contentExtraStyle = '';
        if (this._contentHeight) {
            contentExtraStyle = ` style="height:${this._contentHeight}px"`;
        }

        const title = (
            `<h2 class="${this._titleCls}">` +
                `<span id="${this._titleAreaId}" title="${escapeAttribute(this._getConfigParam('title', ''))}">${this._getConfigParam('title', '')}</span>` +
            '</h2>'
        );

        this._updateComponentElement(
            '<div class="popup-wrapper">' +
                '<div class="popup-container">' +
                    '<div class="popup-heading">' +
                        `<div class="popup-heading-area">${closeButton}${title}</div>` +
                    '</div>' +
                    '<div class="popup-content">' +
                        `<div class="popup-content-area" id="${this._popupContentAreaId}"${contentExtraStyle}>${this._getContentArea()}</div>` +
                    '</div>' +
                '</div>' +
            '</div>'
        );
    }

    _getContentArea() {
        let buttonsArea = '';
        if (this._buttons.length > 0) {
            buttonsArea = this._getButtonsArea();
        }
        return `<form id="${this.getId()}-form">${this._getConfigParam('content', '')}${buttonsArea}</form>`;
    }

    _getButtonsArea() {
        return (
            `<div class="btns-box" id="${this._actionButtonsId}">` +
                '<div class="box-area">' +
                    '<div class="form-row">' +
                        '<div class="single-row">' +
                            `<div class="field-value" id="${this._rightActionButtonsAreaId}"> </div>` +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );
    }

    _addEvents() {
        super._addEvents();

        this._buttons.each(buttonDesc => {
            let buttonId = '';
            if ('undefined' !== typeof buttonDesc.id) {
                buttonId = ` id="${buttonDesc.id}" `;
            }
            if ('undefined' === typeof buttonDesc.class) {
                buttonDesc.class = '';
            }
            let buttonDisabled = '';
            if ('undefined' !== typeof buttonDesc.disabled && buttonDesc.disabled) {
                buttonDisabled = 'disabled="disabled"';
            }
            const buttonContainer = document.createElement('span');
            buttonContainer.className = `btn ${buttonDesc.class}`;
            buttonContainer.innerHTML = `<button ${buttonId} value="" type="button" ${buttonDisabled}>${buttonDesc.title}</button>`;
            buttonContainer.querySelector('button').addEventListener('click', event => {
                buttonDesc.handler(event, this);
            });
            render(document.getElementById(this._rightActionButtonsAreaId), buttonContainer);
        });

        if (this._closeButtonEnabled) {
            document.getElementById(`${this._id}-close`).addEventListener('click', event => {
                this._onCloseClick(event);
            });
        }

        this.show();
    }

    _onCloseClick() {
        this.hide();
    }
}

// TODO EXTNODEJS-90: Eliminate Class.create() usage for Jsw classes
// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
Popup.subclasses = [];
