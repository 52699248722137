// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import { Text, Translate } from '@plesk/ui-library';
import { NotificationPropType } from './lib/PropTypes';

const NotificationDate = ({ notification, ...props }) => {
    const { displayDate } = notification;
    const today = new Date();
    const date = new Date(displayDate);
    const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

    return isToday ? (
        <Translate
            component={Text}
            nowrap
            content="notification-center.timeFormat"
            params={{
                hours: `0${date.getHours()}`.slice(-2),
                minutes: `0${date.getMinutes()}`.slice(-2),
            }}
            {...props}
        />
    ) : (
        <Translate
            component={Text}
            nowrap
            content="notification-center.dateFormat"
            params={{
                day: date.getDate(),
                month: (<Translate content={`notification-center.month${date.getMonth()}`} />),
            }}
            {...props}
        />
    );
};

NotificationDate.propTypes = {
    notification: NotificationPropType.isRequired,
};

export default NotificationDate;
