// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

let isOpened = false;
let ws = null;
const messageSubscriptions = [];
const openSubscriptions = [];
const closeSubscriptions = [];

// eslint-disable-next-line no-console
const handleError = error => console.error(error);

const wsConnect = (attempt = 0) => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    ws = new WebSocket(`${protocol}://${window.location.host}/ws`);
    ws.onmessage = message => {
        const messageData = JSON.parse(message.data);
        const { action, data, error } = messageData;
        if (error) {
            if (error === 'Unauthorized') {
                return;
            }
            throw new Error(`Unknown error ${error}`);
        }

        if (typeof messageSubscriptions[action] === 'undefined') {
            return;
        }

        messageSubscriptions[action].each(callback => {
            try {
                callback(data);
            } catch (e) {
                handleError(e);
            }
        });
    };

    ws.onopen = event => {
        isOpened = true;
        openSubscriptions.each(callback => {
            try {
                callback(event);
            } catch (e) {
                handleError(e);
            }
        });
    };

    ws.onclose = event => {
        isOpened = false;
        ws = null;
        closeSubscriptions.each(callback => {
            try {
                callback(event);
            } catch (e) {
                handleError(e);
            }
        });
        if (1000 !== event.code) {
            setTimeout(() => {
                wsConnect(attempt + 1);
            }, 2000 * attempt);
        }
    };
};

const PleskWSBinding = function ({ actions, onOpen, onClose }) {
    this.actions = actions;
    this.onOpen = onOpen;
    this.onClose = onClose;

    this.isReady = () => ws && 1 === ws.readyState;

    this.close = () => {
        if (this.actions) {
            for (const action in actions) {
                if (!actions.hasOwnProperty(action) || typeof messageSubscriptions[action] === 'undefined') {
                    return;
                }
                const messageSubscriptionsIndex = messageSubscriptions[action].indexOf(actions[action]);
                if (messageSubscriptionsIndex >= 0) {
                    messageSubscriptions[action].splice(messageSubscriptionsIndex, 1);
                }
            }
        }

        if (this.onOpen) {
            const openSubscriptionsIndex = openSubscriptions.indexOf(onOpen);
            if (openSubscriptionsIndex >= 0) {
                openSubscriptions.splice(openSubscriptionsIndex, 1);
            }
        }

        if (this.onClose) {
            const closeSubscriptionsIndex = closeSubscriptions.indexOf(onClose);
            if (closeSubscriptionsIndex >= 0) {
                closeSubscriptions.splice(closeSubscriptionsIndex, 1);
            }
        }
    };
};

export default {
    bind: ({ actions, onOpen, onClose }) => {
        if (!ws) {
            wsConnect();
        }
        if (actions) {
            for (const action in actions) {
                if (!actions.hasOwnProperty(action)) {
                    return;
                }
                if (typeof messageSubscriptions[action] === 'undefined') {
                    messageSubscriptions[action] = [];
                }
                if (messageSubscriptions[action].indexOf(actions[action]) < 0) {
                    messageSubscriptions[action].push(actions[action]);
                }
            }
        }

        if (onOpen) {
            if (openSubscriptions.indexOf(onOpen) < 0) {
                openSubscriptions.push(onOpen);
            }
            if (isOpened) {
                onOpen();
            }
        }

        if (onClose && closeSubscriptions.indexOf(onClose) < 0) {
            closeSubscriptions.push(onClose);
        }

        return new PleskWSBinding({
            actions,
            onOpen,
            onClose,
        });
    },
};
