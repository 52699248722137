// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

import { createElement, StrictMode } from 'react';
import NotFound from './NotFound';

const App = () => (
    <StrictMode>
        <NotFound />
    </StrictMode>
);

export default App;
