// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

export default url => {
    if (typeof url !== 'string') {
        return null;
    }

    const baseUrl = (global.Jsw && global.Jsw.baseUrl) || '';

    if (
        url.startsWith('/services/') ||
        url.startsWith('/server/spamassassin') ||
        url.startsWith('/server/mail_') ||
        url.startsWith('/plesk/') ||
        url.startsWith('/admin/') ||
        url.startsWith('/smb/') ||
        (baseUrl !== '' && url.startsWith(`${baseUrl}/`)) ||
        (!url.startsWith('/') && (url.substr(0, 2) !== '^/')) ||
        url.startsWith('/modules/')
    ) {
        return url;
    }
    if (url.substr(0, 2) === '^/') {
        return url.toString().substr(1);
    }

    return baseUrl + url;
};
