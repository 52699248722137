// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { render } from 'react-dom';
import { createElement, createPortal } from 'react';
import { LocaleProvider } from '@plesk/ui-library';
import { api } from 'jsw';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import require from './require';
import sentry from 'common/sentry';

import './index.less';

export { require };
export Form from './components/Form';
export Routes from './components/Routes';
export PageSidebar from './components/PageSidebar';
export PageContentHeader from './components/PageContentHeader';
export PageHeader from './components/PageHeader';

const isInFrame = () => {
    try {
        if (window.self === window.top) {
            return false;
        }
    } catch (e) {
    }

    return true;
};

let app = null;
let data = {};

export const run = ({ data: newData = {}, ...props }) => {
    data = newData;
    const { config } = props;

    if (config.feedback) {
        sentry(config.feedback);
    }

    if (isInFrame()) {
        document.body.classList.add('page-content-only');
    }

    app = render(
        <App {...props} />,
        document.body
    );
};

export const addWidget = (Component, { locale, ...props }, container) => {
    let widget = <Component {...props} />;

    if (locale) {
        widget = <LocaleProvider messages={locale}>{widget}</LocaleProvider>;
    }

    widget = (
        <ErrorBoundary>
            {widget}
        </ErrorBoundary>
    );

    if (container) {
        widget = createPortal(widget, container);
    }

    app.addWidget(widget);
};

export const getData = url => {
    if (data[url]) {
        return new Promise(resolve => {
            resolve(data[url]);
            delete data[url];
        });
    }

    return api.get(url);
};
