// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Breadcrumbs, Heading, Text } from '@plesk/ui-library';
import { bar, ObjectSwitcher } from 'jsw';
import Link from './Link';
import JswComponent from './JswComponent';

class StickyNav {
    constructor(element) {
        this.element = element;
        const pageHeaderHeight = Element.getHeight(document.querySelector('.pul-layout__header-inner'));
        const tabsArea = this.element.querySelector('.tabs-area');

        tabsArea.style.top = `${pageHeaderHeight}px`;
        this.elementHeight = Element.getHeight(tabsArea) + parseInt(this.element.style.paddingBottom);
        this.offsetTop = Element.cumulativeOffset(tabsArea).top - Element.cumulativeOffset(document.querySelector('.pul-layout__main-inner')).top;

        window.addEventListener('resize', this.update);
        window.addEventListener('scroll', this.update);
        document.addEventListener('mousewheel', this.update);
    }

    update = () => {
        if (document.viewport.getScrollOffsets().top > (this.offsetTop - 3)) {
            this.element.style.paddingBottom = `${this.elementHeight - 3}px`;
            this.element.classList.add('sticky-nav');
        } else {
            this.element.style.paddingBottom = null;
            this.element.classList.remove('sticky-nav');
        }
    }
}

const PageContentHeader = ({
    breadcrumbs,
    tabs,
    tabsHasRootPage,
    navigationTabs,
    hidePathbar,
    hideHeading,
    wizardTitle,
    pageTitle,
    pageSubtitle,
    objectSwitcherParams,
    forceObjectSwitcher,
    isClassicMode,
}) => {
    useEffect(() => {
        if (document.getElementById('classic-mode-navigation')) {
            new StickyNav(document.getElementById('classic-mode-navigation'));
        }
    }, []);

    breadcrumbs = Array.isArray(breadcrumbs) ? [...breadcrumbs] : [];
    let currentPage = breadcrumbs.pop();
    let addObjectSwitcher = forceObjectSwitcher;
    let objectName = null;
    let pageTitleSuffix = null;

    if (currentPage && tabs && tabsHasRootPage) {
        currentPage = breadcrumbs.pop();
        if (!pageTitle) {
            pageTitle = currentPage.title;
        }
    }

    if (currentPage && navigationTabs) {
        currentPage = breadcrumbs.pop();
        pageTitle = currentPage.title;

        // subscription horizontal navigation
        addObjectSwitcher = pageTitle.indexOf('</b>') !== -1;
    }

    const match = pageTitle && pageTitle.match(/<b>(.+)<\/b>/);
    if (match) {
        objectName = match[1];
        pageTitleSuffix = pageTitle.substr(match.index + match[0].length);
        pageTitle = pageTitle.substr(0, match.index);

        const previousPage = breadcrumbs[breadcrumbs.length - 1];
        if (previousPage && objectName === previousPage.title.stripTags()) {
            objectName = (
                <a href={previousPage.href}>{previousPage.title.stripTags()}</a>
            );
            breadcrumbs.pop();

            // domain horizontal navigation
            addObjectSwitcher = true;
        } else {
            objectName = (
                // TODO EXTSSLIT-590: EXTPHPCOMP-33: Broken header in Plesk 18.0.19
                // eslint-disable-next-line react/no-danger
                <span dangerouslySetInnerHTML={{ __html: objectName }} />
            );
        }
    }

    if ((hidePathbar || breadcrumbs.length) && hideHeading && (!isClassicMode || !navigationTabs)) {
        return null;
    }

    return (
        <PageHeader
            breadcrumbs={!hidePathbar && breadcrumbs.length ? (
                <Breadcrumbs data-type="page-pathbar">
                    {breadcrumbs.map(({ title, href }) => (
                        <Link key={href} to={href}>{title.stripTags()}</Link>
                    ))}
                </Breadcrumbs>
            ) : null}
            tabs={isClassicMode && navigationTabs ? (
                <JswComponent>
                    {() => new bar.Tabs({
                        id: 'classic-mode-navigation',
                        cls: 'tabs',
                        items: navigationTabs,
                    })}
                </JswComponent>
            ) : null}
            title={hideHeading ? null : (
                <div>
                    {wizardTitle ? (
                        <Heading>{wizardTitle}</Heading>
                    ) : null}
                    {objectName ? (
                        <Heading level={wizardTitle ? 3 : 1} data-type="page-title">
                            {pageTitle ? (
                                // TODO EXTLETSENC-726: EXTSSLIT-590: EXTPHPCOMP-33: Broken header in Plesk 18.0.19
                                // eslint-disable-next-line react/no-danger
                                <span dangerouslySetInnerHTML={{ __html: pageTitle }} />
                            ) : null}
                            <Fragment>
                                {objectName}
                                {objectSwitcherParams && addObjectSwitcher ? (
                                    <Fragment>
                                        {' '}
                                        <JswComponent component="span">
                                            {() => new ObjectSwitcher(objectSwitcherParams)}
                                        </JswComponent>
                                    </Fragment>
                                ) : null}
                            </Fragment>
                            {pageTitleSuffix}
                        </Heading>
                    ) : (
                        // eslint-disable-next-line react/no-danger
                        <Heading level={wizardTitle ? 3 : 1} data-type="page-title" dangerouslySetInnerHTML={{ __html: pageTitle }} />
                    )}
                </div>
            )}
        >
            {!hideHeading && pageSubtitle ? (
                <Text intent="muted">{pageSubtitle}</Text>
            ) : null}
        </PageHeader>
    );
};

PageContentHeader.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.string,
    })),
    tabs: PropTypes.array,
    tabsHasRootPage: PropTypes.bool,
    navigationTabs: PropTypes.array,
    hidePathbar: PropTypes.bool,
    hideHeading: PropTypes.bool,
    wizardTitle: PropTypes.string,
    pageTitle: PropTypes.string,
    pageSubtitle: PropTypes.string,
    objectSwitcherParams: PropTypes.object,
    forceObjectSwitcher: PropTypes.bool,
    isClassicMode: PropTypes.bool,
};

PageContentHeader.defaultProps = {
    breadcrumbs: [],
    tabs: null,
    tabsHasRootPage: false,
    navigationTabs: null,
    hidePathbar: false,
    hideHeading: false,
    wizardTitle: null,
    pageTitle: null,
    pageSubtitle: null,
    objectSwitcherParams: null,
    forceObjectSwitcher: false,
    isClassicMode: false,
};

export default PageContentHeader;
