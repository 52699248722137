// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Link as RouteLink, matchPath } from 'react-router-dom';
import routes from '../routes';

const Link = ({ to, ...props }, { router }) => {
    const isMatch = router && routes.some(route => matchPath(to, route));

    if (isMatch) {
        return <RouteLink to={to} {...props} />;
    }

    return <a href={to} {...props} />;
};

Link.propTypes = {
    to: PropTypes.string,
};

Link.defaultProps = {
    to: null,
};

Link.contextTypes = {
    router: PropTypes.object,
};

export default Link;
