// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

/* eslint-disable react/no-deprecated */

import { LocaleProvider } from '@plesk/ui-library';
import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { prepareUrl } from 'jsw';
import { Redirect, withRouter } from 'react-router-dom';
import { getData } from '..';

class RequireComponent extends Component {
    state = {
        Component: null,
        data: {},
    };

    componentDidMount() {
        const { location, component } = this.props;
        const { pathname, search, hash } = location;

        this.load(component, this.props.dataPath || `${pathname}${search}${hash}`);
    }

    componentWillReceiveProps({ location, component }) {
        if (this.props.location !== location) {
            const { pathname, search, hash } = location;
            this.load(component, this.props.dataPath || `${pathname}${search}${hash}`);
        }
    }

    resolveComponent(component) {
        if (typeof component === 'string') {
            return new Promise(resolve => {
                requirejs([component], module => {
                    resolve(module);
                });
            });
        }

        if (typeof component === 'function') {
            return component();
        }

        return Promise.resolve(component);
    }

    load(component, dataPath) {
        Promise.all([
            this.resolveComponent(component),
            getData(dataPath),
        ])
            .then(([module, data]) => {
                this.setState({
                    Component: module.default || module,
                    data,
                });
            });
    }

    render() {
        const { Component, data } = this.state;
        const { locale, ...props } = data;

        if (data.redirect) {
            const fullUrl = prepareUrl(data.redirect);

            return <Redirect to={fullUrl} />;
        }

        if (!Component || !locale || !props) {
            return null;
        }

        return (
            <LocaleProvider messages={locale}>
                <Component {...props} />
            </LocaleProvider>
        );
    }
}

RequireComponent.propTypes = {
    location: PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
    dataPath: PropTypes.string,
};

RequireComponent.defaultProps = {
    dataPath: null,
};

export default withRouter(RequireComponent);
