// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import UAT from 'common/user-activity-tracking';
import addUrlParams from './addUrlParams';
import emptyFn from './emptyFn';

const request = (url, config = {}) => {
    if (window.Ajax) {
        window.Ajax.activeRequestCount++;
    }

    const headers = {
        'X-Requested-With': 'XMLHttpRequest',
    };
    if (!fetch.polyfill) {
        const tokenEl = document.getElementById('forgery_protection_token');
        if (tokenEl) {
            headers['X-Forgery-Protection-Token'] = tokenEl.content;
        }
    }

    config = {
        credentials: 'same-origin',
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
    };

    return fetch(url, config)
        .then(response => {
            if (response.status === 400) {
                top.window.location.reload();
                return new Promise(emptyFn);
            }
            return response.json();
        })
        .catch(error => {
            if (error instanceof TypeError) {
                if (window.Ajax) {
                    window.Ajax.activeRequestCount--;
                }
                return new Promise(emptyFn);
            }
            throw error;
        })
        .finally(() => {
            if (window.Ajax) {
                window.Ajax.activeRequestCount--;
            }
        });
};

export default {
    get: (url, params = {}) => request(addUrlParams(url, params), { method: 'GET' }),
    post: (url, params = {}) => {
        UAT.dispatchAction('REQUEST', { url });
        return request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(params),
        });
    },
};
