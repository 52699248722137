// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { init, configureScope, Integrations } from '@sentry/browser';

export default ({ sentry }) => {
    if (!sentry) {
        return;
    }

    const { tags = {}, ...options } = sentry;
    init({
        integrations: [new Integrations.Breadcrumbs({ console: false })],
        ...options,
    });
    configureScope(scope => {
        scope.setTag('logger', 'javascript');
        for (const tag in tags) {
            scope.setTag(tag, tags[tag]);
        }
    });
};
