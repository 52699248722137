// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/**
 * Creates namespaces to be used for scoping variables and classes so that they are not global.
 * Specifying the last node of a namespace implicitly creates all other nodes. Usage:
 *
 *     namespace('Module.SubModule.list');
 *
 * @param {String} ns
 * @param {Boolean} autoCreate=true
 * @return {Object} The object that is the namespace or class name.
 */
export default (ns, autoCreate = true) => {
    const nsParts = ns.split('.');
    let root = window;

    for (let partIndex = 0; partIndex < nsParts.length; partIndex++) {
        if (typeof root[nsParts[partIndex]] === 'undefined') {
            if (autoCreate) {
                root[nsParts[partIndex]] = {};
            } else {
                return undefined;
            }
        }

        root = root[nsParts[partIndex]];
    }

    return root;
};
