// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Tooltip } from './tooltip';
import { Locale } from './locale';
import render from './render';

const registeredComponents = {};

export const registerComponent = (identity, component) => {
    registeredComponents[identity] = component;
};

/**
 * Returns an component by identity.
 * @param {String} identity The identity of the component.
 * @returns {Object} The component, undefined if not found.
 */
export const getComponent = identity => registeredComponents[identity];

/**
 * The base class for all Jsw components.
 */
export class Component {
    /**
     * @cfg {String} id The unique id of this component instance.
     */
    /**
     * @cfg {String} cls CSS class names to apply for root element.
     */
    /**
     * @cfg {Object} attrs HTML attributes to apply for root element.
     */
    /**
     * @cfg {String} wrapperClass
     */
    /**
     * @cfg {Element|String} renderTo
     */
    /**
     * @cfg {String} renderMode=bottom
     */
    /**
     * @cfg {Element|String} applyTo
     */
    /**
     * @cfg {Object} locale
     */
    /**
     * @cfg {Boolean} hidden=false Hide component. See methods {@link #hide}/{@link #show}
     */

    static genId = 1;

    constructor(config = {}) {
        this.initialize(config);
    }

    // TODO EXTNODEJS-90: EXTDOCKER-50: PMT-4391: Need to move this logic to constructor
    initialize(config) {
        this._initConfiguration(config);
        this._registerComponent();

        this._initComponentElement();

        if (this._autoRender && ((null !== this._renderTarget) || (typeof this._applyTargetId === 'string' ? document.getElementById(this._applyTargetId) : this._applyTargetId))) {
            this.render();
        }

        if (this._getConfigParam('hidden', false)) {
            this.hide();
        }
    }

    _initConfiguration(config) {
        this.initialConfig = config;
        this._config = this.initialConfig;

        this._cls = this._getConfigParam('cls', '');
        this._attrs = this._getConfigParam('attrs', {});
        this._wrapperClass = this._getConfigParam('wrapperClass', '');
        this._autoRender = this._getConfigParam('autoRender', true);
        this._renderTarget = (this._getConfigParam('renderTo', null));
        if (typeof this._renderTarget === 'string') {
            this._renderTarget = document.getElementById(this._renderTarget);
        }
        this._renderMode = this._getConfigParam('renderMode', 'bottom');
        this._applyTargetId = this._getConfigParam('applyTo', null);
        this.setLocale(this._getConfigParam('locale'));

        this._id = (this._applyTargetId)
            ? this._applyTargetId
            : this._getConfigParam('id', null);

        if (null === this._id) {
            this._id = `gen-id-${Component.genId++}`;
            this._idIsRandom = true;
        }

        this._tag = (this._applyTargetId)
            ? (typeof this._applyTargetId === 'string' ? document.getElementById(this._applyTargetId) : this._applyTargetId).tagName
            : this._getConfigParam('tag', this._tag || 'div');
    }

    _registerComponent() {
        registerComponent(this._id, this);
    }

    _initComponentElement() {
        if (this._applyTargetId) {
            this._componentElement = typeof this._applyTargetId === 'string' ? document.getElementById(this._applyTargetId) : this._applyTargetId;
            return;
        }

        this._componentElement = document.createElement(this._tag);
        Object.keys(this._attrs).forEach(name => {
            this._componentElement.setAttribute(name, this._attrs[name]);
        });
        this._componentElement.id = this._id;
        this._componentElement.className = this._cls;
    }

    _initDisablerOverlay() {
        this._disablerOverlay = document.createElement('div');

        this._disablerOverlay.style.display = 'none';
        this._disablerOverlay.style.zIndex = this._componentElement.style.zIndex + 1000;

        this._disablerOverlay.classList.add('content-blocker');
        this._componentElement.appendChild(this._disablerOverlay);
    }

    addEventObserver(eventName, handler) {
        if ('undefined' !== typeof handler) {
            this._componentElement.addEventListener(eventName, handler);
        }
    }

    removeEventObserver(eventName, handler) {
        if ('undefined' !== typeof handler) {
            this._componentElement.removeEventListener(eventName, handler);
        }
    }

    _hasConfigParam(name) {
        return 'undefined' !== typeof this._config[name];
    }

    _getConfigParam(name, defaultValue) {
        return this._hasConfigParam(name)
            ? this._config[name]
            : defaultValue;
    }

    _getRandomNumber() {
        return Math.floor(1000000 * Math.random());
    }

    _addEvents() {
        // basic empty implementation
    }

    _addTooltips() {
        const description = this._getConfigParam('description');
        if (description) {
            this._tooltip = Tooltip.init(this._componentElement, { text: description });
        }
    }

    _updateComponentElement(content) {
        const oldElements = this._componentElement.childNodes;
        if (!oldElements.length) {
            render(this._componentElement, content);
            return;
        }

        render(this._componentElement, content, 'top');

        oldElements.forEach(oldElement => {
            if ('undefined' === typeof oldElement.id || '' === oldElement.id) {
                return;
            }

            const newElement = this._componentElement.querySelector(`#${oldElement.id}`);

            if (newElement) {
                newElement.parentNode.replaceChild(oldElement, newElement);
            }
        });
    }

    /**
     * See Component.wrapperClass for details.
     * @returns {String}
     */
    getWrapperClass() {
        return this._wrapperClass;
    }

    /**
     * Returns id of this component instance.
     * @returns {String}
     */
    getId() {
        return this._id;
    }

    /**
     * Set render target element.
     * @param {Element} target
     */
    setRenderTarget(target) {
        this._renderTarget = target;
    }

    /**
     * Get render target element.
     * @returns {Element}
     */
    getRenderTarget() {
        return this._renderTarget;
    }

    /**
     * Show component.
     */
    show() {
        this._componentElement.style.display = '';
    }

    /**
     * Hide component.
     */
    hide() {
        this._componentElement.style.display = 'none';
    }

    /**
     * Render component.
     */
    render() {
        if (this._renderTarget) {
            render(this._renderTarget, this._componentElement, this._renderMode);
        }

        this._addEvents();
        this._addTooltips();
    }

    setLocale(locale) {
        if (!(locale instanceof Locale)) {
            locale = new Locale(locale);
        }
        this.lmsg = locale.lmsg.bind(locale);
        this._locale = locale;
    }

    getLocale() {
        return this._locale;
    }
}

// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
Component.subclasses = [];
