// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

import { Container } from './container';

/**
 * Example usage:
 *
 *     @example
 *     new CollapsibleBlock({
 *         renderTo: document.body,
 *         cls: 'example',
 *         title: 'Collapsible block',
 *         items: [
 *             new Box({
 *                 html: 'Content of collapsible block.'
 *             }),
 *         ],
 *     });
 */
export class CollapsibleBlock extends Container {
    /**
     * @cfg {Boolean} collapsed=true
     */
    /**
     * @cfg {String} title
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._blockAreaId = `${this._id}-block`;
        this._titleAreaId = `${this._id}-title-area`;
        this._contentAreaId = `${this._id}-content-area`;
    }

    _initComponentElement() {
        super._initComponentElement();

        this._updateComponentElement(
            `<div class="${this._cls}-block${this._getConfigParam('collapsed', true) ? ' hide' : ''}" id="${this._blockAreaId}">` +
                `<div class="${this._cls}-title" id="${this._titleAreaId}">` +
                    `<span>${this._getConfigParam('title', '')}</span>` +
                '</div>' +
                `<div class="${this._cls}-content" id="${this._contentAreaId}"></div>` +
            '</div>'
        );
    }

    _addEvents() {
        super._addEvents();
        document.getElementById(this._titleAreaId).querySelector('span').addEventListener('click', this._onTitleClickEvent.bind(this));
    }

    _onTitleClickEvent(event) {
        event.stopPropagation();
        document.getElementById(this._blockAreaId).classList.toggle('hide');
    }
}
