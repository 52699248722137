// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

const observers = {};
const getObservers = ns => {
    if ('undefined' === typeof observers[ns]) {
        observers[ns] = [];
    }
    return observers[ns];
};

/**
 * @class Observer
 * @singleton
 */
export const Observer = (function () {
    return {
        /**
         * @param {Function} callback
         * @param {String} ns
         */
        append(callback, ns) {
            getObservers(ns).push(callback);
        },

        /**
         * @param {Function} callback
         * @param {String} ns
         */
        appendAndCall(callback, ns) {
            getObservers(ns).push(callback);
            callback();
        },

        /**
         * @param {Object} event
         * @param {String} ns
         */
        notify(event, ns) {
            getObservers(ns).each(function (callback) {
                callback(event);
            });
        },
    };
}());
