// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import {
    Button,
    List,
    ListEmptyView,
    SearchBar,
    Toolbar,
    ToolbarExpander,
    ToolbarGroup,
    Translate,
} from '@plesk/ui-library';
import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import applyFilter from '../lib/applyFilter';
import { NotificationFilterPropType, NotificationPropType } from '../lib/PropTypes';
import SubjectColumn from './SubjectColumn';
import DateColumn from './DateColumn';
import NotificationActions from '../NotificationActions';
import FilterMenu from './FilterMenu';

const NotificationCenterList = ({
    notifications,
    filter,
    onUpdateFilter,
    onSelectNotification,
    onUpdateNotifications,
    onRemoveNotification,
    onMarkAllAsRead,
    onOpenSettings,
}, { locale }) => {
    const notificationsToDisplay = applyFilter(notifications, filter);
    const filterCount = Object.values(filter).reduce((r, v) => r + (v ? 1 : 0), 0);
    const columns = [
        {
            key: 'subject',
            type: 'title',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <SubjectColumn
                    notification={notification}
                    onSelectNotification={onSelectNotification}
                    onUpdateNotifications={onUpdateNotifications}
                />
            ),
        },
        {
            key: 'date',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <DateColumn
                    notification={notification}
                    onSelectNotification={onSelectNotification}
                />
            ),
            width: '10%',
        },
        {
            key: 'actions',
            type: 'actions',
            width: '1%',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <NotificationActions
                    notification={notification}
                    onUpdateNotifications={onUpdateNotifications}
                    onRemoveNotification={onRemoveNotification}
                />
            ),
        },
    ];

    const toolbar = (
        <Toolbar>
            <ToolbarGroup title="">
                <Button data-type="mark-all-as-read" icon="mail" onClick={onMarkAllAsRead}>
                    <Translate content="notification-center.markAllAsRead" />
                </Button>
            </ToolbarGroup>
            <ToolbarGroup title="">
                <Button icon="gear" onClick={onOpenSettings} />
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title="">
                <FilterMenu
                    filter={filter}
                    filterCount={filterCount}
                    onUpdateFilter={onUpdateFilter}
                />
            </ToolbarGroup>
            <ToolbarGroup title="">
                <SearchBar
                    inputProps={{ placeholder: locale.lmsg('notification-center.searchPlaceholder'), value: filter.search }}
                    onTyping={value => onUpdateFilter({ search: value })}
                    minWidth={200}
                />
            </ToolbarGroup>
        </Toolbar>
    );

    return (
        <Fragment>
            {toolbar}
            <List
                rowKey="id"
                columns={columns}
                data={notificationsToDisplay}
                expandedRows={['subject']}
                emptyView={filterCount ? (
                    <ListEmptyView
                        reason="filtered"
                        title={<Translate content="notification-center.listFilteredTitle" />}
                        description={<Translate content="notification-center.listFilteredDescription" />}
                    />
                ) : (
                    <ListEmptyView
                        title={<Translate content="notification-center.listEmptyTitle" />}
                    />
                )}
            />
        </Fragment>
    );
};

NotificationCenterList.propTypes = {
    notifications: PropTypes.arrayOf(NotificationPropType.isRequired).isRequired,
    filter: NotificationFilterPropType,
    onUpdateNotifications: PropTypes.func.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onRemoveNotification: PropTypes.func.isRequired,
    onMarkAllAsRead: PropTypes.func.isRequired,
    onOpenSettings: PropTypes.func.isRequired,
};

NotificationCenterList.defaultProps = {
    filter: null,
};

NotificationCenterList.contextTypes = {
    locale: PropTypes.object,
};

export default NotificationCenterList;
